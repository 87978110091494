import gql from "graphql-tag";
import { FieldConfig } from "./fields";

export const GetChannels = gql`
	query GetChannels($companyId: Int) {
		channels(condition: {enabled: true}) {
			nodes {
				id
				name
				displayName
				enabled

				fieldConfigs {
					nodes {
						...FieldConfig

						fieldsByConfig(condition: {companyId: $companyId}) {
							nodes {
								id
								value
							}
						}
					}
				}

				companyChannels(condition: {companyId: $companyId}) {
					nodes {
						id
						enabled
					}
				}
			}
		}
	}
	${FieldConfig}
`;

export const CreateCompanyChannel = gql`
	mutation CreateCompanyChannel($input: CreateCompanyChannelInput!) {
		createCompanyChannel(input: $input) {
			company {
				id
				name
				companyChannels {
					nodes {
						id
						enabled
						channel {
							id
							name
							displayName
							enabled
						}
					}
				}
			}
		}
	}
`;

export const UpdateCompanyChannel = gql`
	mutation UpdateCompanyChannel($input: UpdateCompanyChannelInput!) {
		updateCompanyChannel(input: $input) {
			companyChannel {
				id
				enabled
				channel {
					id
					name
					displayName
					enabled
				}
			}
		}
	}
`;
